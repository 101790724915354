export const filterRanges = {
    budget: [15, 3740000000],
}

export const orderColumns = [
    {
        field: "id",
        label: "№ ЗАЯВКИ",
        shortLabel: "№",
        placeholder: "№",
        width: "22",
        show: true,
        iSearchable: true,
    },
    {
        field: "status_id",
        label: "СТАТУС ЗАЯВКИ",
        shortLabel: "Статус",
        placeholder: "Искать",
        width: "150",
        selectable: true,
        selectedItem: null,
        selectList: 'order_statuses',
        useSelectTemplate: true,
        useOrderStatusTemplate: true,
        show: true,
    },
    {
        field: "tender_id",
        label: "№ ТЕНДЕРА",
        shortLabel: "№ Тнд",
        placeholder: "№ Тендера",
        width: "22",
        show: true,
        iSearchable: true,
    },
    {
        field: "tender_name",
        label: "НАИМЕНОВАНИЕ ТЕНДЕРА",
        shortLabel: "Тендер",
        placeholder: "Искать",
        width: "22",
        show: true,
        iSearchable: true,
    },
    {
        field: "tender_status",
        label: "СТАТУС ТЕНДЕРА",
        shortLabel: "Статус тнд",
        placeholder: "Выберите",
        width: "150",
        selectable: true,
        selectedItem: null,
        selectList: 'order_statuses',
        useSelectTemplate: true,
        useTenderStatusTemplate: true,
        show: true,
    },
    {
        field: "date",
        label: "ДАТЫ ПРИЕМА ЗАЯВОК",
        shortLabel: "ДАТЫ ПРИЕМА",
        placeholder: "Фильтр дат",
        width: "140",
        useDatesTemplate: true,
        iSearchable: true,
        show: true,
    },
]
