<template>
  <div class="columns mb-2-mobile-only">
    <div class="column is-4">
      <div class="pl-1 is-justify-content-center-mobile-only">
        <b-pagination
          :total="totalCount"
          v-model="current"
          :range-before="rangeBefore"
          :range-after="rangeAfter"
          :order="order"
          :size="size"
          :simple="isSimple"
          :rounded="isRounded"
          :per-page="perPage"
          :icon-prev="prevIcon"
          :icon-next="nextIcon"
          aria-next-label="Next page"
          aria-previous-label="Previous page"
          aria-page-label="Page"
          aria-current-label="Current page"
          :page-input="hasInput"
          :page-input-position="inputPosition"
          :debounce-page-input="inputDebounce"
          class="custom-pagination"
          @change="updatePagination"
        >
        </b-pagination>
      </div>
    </div>

    <div class="column is-4 has-text-centered">
      <button
        @click="showMore"
        class="button is-normal border-button"
        v-if="showShowMore"
      >
        <span>Показать еще</span>
      </button>
    </div>

    <div class="column is-4 is-hidden-mobile"></div>
  </div>
</template>

<script>
import { OrderListFullBasicMixin } from "./OrderListFullBasicMixin";

export default {
  components: {},
  mixins: [OrderListFullBasicMixin],
  data() {
    return {
      current: null,
      rangeBefore: 1,
      rangeAfter: 2,
      order: "",
      size: "",
      isSimple: false,
      isRounded: false,
      hasInput: false,
      prevIcon: "chevron-left",
      nextIcon: "chevron-right",
      inputPosition: "",
      inputDebounce: 100,
    };
  },
  mounted() {
    this.current = this.currentPage;
  },
  methods: {
    updatePagination() {
      this.$store.commit("order/setCurrentPage", this.current);
      this.$store.dispatch("order/load", {showMore: false, demoPage: false});
    },
    showMore() {
      this.$store.dispatch("order/load", {showMore: true, demoPage: false});
    },
  },
  computed: {
    showShowMore() {
      return this.$store.getters["order/showShowMore"];
    },
  },
};
</script>
