<template>
  <b-sidebar
    type="is-light"
    class="custom-right-sidebar"
    :fullheight="true"
    :overlay="true"
    :right="true"
    v-model="$store.state.order.showSetup"
  >
    <div class="pt-5 pb-5 pl-5 pr-5">
      <div
        class="is-flex is-align-items-center is-justify-content-flex-start mb-4"
      >
        <div @click="toggleShowSetup" class="mr-6 cursor-pointer">
          <ArrowLeft />
        </div>

        <h3 class="is-size-5-5 is-uppercase has-text-weight-bold">
          Настроить отображение списка
        </h3>
      </div>

      <div class="mb-5">
        <div
          v-for="item in setuporderColumns"
          :key="item.field"
          class="is-flex is-align-items-center cursor-pointer setup-order-column"
          @click="toggleWisibility(item)"
        >
          <div class="mr-1 pt-1">
            <BoxChecked v-if="item.show" />
            <BoxUnchecked v-else />
          </div>
          <div class="p-1 is-size-6 has-text-weight-medium">
            {{ item.label }}
          </div>
        </div>
      </div>

      <div class="mb-4">
        <button
          class="button is-small select-button mr-3"
          @click="selectAllItems"
        >
          <span class="icon is-small">
            <BoxChecked />
          </span>
          <span>Выбрать все</span>
        </button>

        <button
          class="button is-small deselect-button"
          @click="deselectAllItems"
        >
          <span class="icon is-small">
            <BoxUnchecked />
          </span>
          <span>Отменить все</span>
        </button>
      </div>

      <hr class="hr" />

      <div class="mb-5">
        <h4 class="has-text-weight-semibold">Тендеров на страницу</h4>
        <ul class="by-page-paginator">
          <li v-for="(item, i) in ordersByPage" :key="i">
            <a
              :class="{ active: item.active }"
              class="item"
              @click="setByPage(i)"
            >
              {{ item.amount }}
            </a>
          </li>
        </ul>
      </div>

      <div class="mb-6">
        <div class="mb-2">
          <button
            class="button is-normal second-button is-fullwidth"
            @click="save"
          >
            <span class="icon is-small">
              <Save />
            </span>
            <span>СОХРАНИТЬ ИЗМЕНЕНИЯ</span>
          </button>
        </div>

        <button
          class="button is-normal border-button is-fullwidth undo-button"
          @click="undo"
        >
          <span>Отменить изменения</span>
        </button>
      </div>

      <div class="has-text-centered reload-button">
        <button class="button is-text" @click="reset">
          <ReloadIcon class="mr-2" />
          <span> Скинуть настройки по умолчанию </span>
        </button>
      </div>
    </div>
  </b-sidebar>
</template>

<script>
import { OrderListFullBasicMixin } from "./OrderListFullBasicMixin";
import _ from "lodash";

import TimeIcon from "@/components/icons/TimeIcon.vue";
import CloseIcon from "@/components/icons/CloseIcon.vue";
import GearIcon from "@/components/icons/GearIcon.vue";
import ArrowLeft from "@/components/icons/ArrowLeft.vue";
import BoxChecked from "@/components/icons/BoxChecked.vue";
import BoxUnchecked from "@/components/icons/BoxUnchecked.vue";
import Save from "@/components/icons/Save.vue";
import ReloadIcon from "@/components/icons/ReloadIcon.vue";

export default {
  components: {
    TimeIcon,
    CloseIcon,
    GearIcon,
    ArrowLeft,
    BoxChecked,
    BoxUnchecked,
    Save,
    ReloadIcon,
  },
  mixins: [OrderListFullBasicMixin],
  data() {
    return {
      overlay: true,
      fullheight: true,
      right: false,
      setuporderColumns: [],
      setuporderColumnsBackupStatic: [],
      setuporderColumnsBackupDynamic: [],
      stt: false,
      ordersByPage: [
        { amount: 10, active: true },
        { amount: 20, active: false },
        { amount: 50, active: false },
        { amount: 100, active: false },
      ],
      ordersByPageBackupStatic: [],
      ordersByPageBackupDynamic: [],
    };
  },
  mounted() {
    const ORDERS_TABLE_SETTINGS = JSON.parse(
      localStorage.getItem("ORDERS_TABLE_SETTINGS")
    );

    let orderColumns = ORDERS_TABLE_SETTINGS
      ? ORDERS_TABLE_SETTINGS.setuporderColumns
      : this._orderColumns;
    let ordersByPage = ORDERS_TABLE_SETTINGS
      ? ORDERS_TABLE_SETTINGS.ordersByPage
      : this.ordersByPage;

    this.setuporderColumnsBackupStatic = _.cloneDeep(this._orderColumns);
    this.ordersByPageBackupStatic = _.cloneDeep(this.ordersByPage);
    this.ordersByPage = _.cloneDeep(ordersByPage);
    this.setuporderColumns = _.cloneDeep(orderColumns);

    this.$store.commit("order/setColumns", _.cloneDeep(this.setuporderColumns));

    const active = this.ordersByPage.find((item) => item.active);
    this.$store.commit("order/setPerPageAmount", active.amount);
  },
  computed: {
    showSetup() {
      return this.$store.state.order.showSetup;
    },
  },
  methods: {
    save() {
      this.$store.commit(
        "order/setColumns",
        _.cloneDeep(this.setuporderColumns)
      );
      const active = this.ordersByPage.find((item) => item.active);
      this.$store.commit("order/setPerPageAmount", active.amount);

      this.$store.commit("order/toggleShowSetup");

      localStorage.setItem(
        "ORDERS_TABLE_SETTINGS",
        JSON.stringify({
          ordersByPage: this.ordersByPage,
          setuporderColumns: this.setuporderColumns,
        })
      );

      this.updateList();
    },
    toggleWisibility(item) {
      for (let i = 0; i < this.setuporderColumns.length; i++) {
        const column = this.setuporderColumns[i];
        if (item.field == column.field) {
          setTimeout(() => {
            this.setuporderColumns[i].show = !this.setuporderColumns[i].show;
          }, 20);
        }
      }
    },
    toggleShowSetup() {
      this.$store.commit("order/toggleShowSetup");
    },
    selectAllItems() {
      this.setuporderColumns.map((item) => {
        item.show = true;
      });
    },
    deselectAllItems() {
      this.setuporderColumns.map((item) => {
        item.show = false;
      });
    },
    reset() {
      this.setuporderColumns = _.cloneDeep(this.setuporderColumnsBackupStatic);
      this.$store.commit(
        "order/setColumns",
        _.cloneDeep(this.setuporderColumnsBackupStatic)
      );

      this.ordersByPage = _.cloneDeep(this.ordersByPageBackupStatic);

      this.$store.commit("order/toggleShowSetup");

      this.save();
    },
    undo() {
      this.setuporderColumns = _.cloneDeep(this.setuporderColumnsBackupDynamic);
      this.ordersByPage = _.cloneDeep(this.ordersByPageBackupDynamic);
    },
    setByPage(i) {
      this.ordersByPage.map((item) => (item.active = false));
      this.ordersByPage[i].active = true;
    },
  },
  watch: {
    showSetup(newVal, oldVal) {
      if (newVal === true) {
        this.setuporderColumnsBackupDynamic = _.cloneDeep(
          this.setuporderColumns
        );
        this.ordersByPageBackupDynamic = _.cloneDeep(this.ordersByPage);
      }
    },
  },
};
</script>

<style scoped>
.select-button {
  color: #a01b5b;
  border-radius: 3px;
  border-color: #a01b5b;
  border-width: 1px;
  padding: 1rem;
  padding-left: 1.3rem;
  padding-right: 1.3rem;
  transition: all 0.3s;
  font-size: 0.9rem;
  text-transform: uppercase;
}
.deselect-button {
  color: #323332;
  border-radius: 3px;
  border-color: #323332;
  border-width: 1px;
  padding: 1rem;
  padding-left: 1.3rem;
  padding-right: 1.3rem;
  transition: all 0.3s;
  font-size: 0.9rem;
  text-transform: uppercase;
}

.undo-button {
  font-size: 0.9rem;
  color: #323332;
  font-weight: 300;
}

.reload-button {
  color: #6f6f6f;
  font-weight: 300;
  font-size: 0.88rem;
}

.hr {
  border-top: 1px solid #f0f0f0;
}

.by-page-paginator {
  border: 1px solid #323233;
  border-radius: 3px;
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  flex-shrink: 1;
  justify-content: flex-start;
  order: 1;
  text-align: center;
  width: max-content;
  overflow: hidden;
}

.by-page-paginator li {
  list-style: none;
}

.by-page-paginator li:not(:last-child) .item {
  border-right: 1px solid #323233;
}

.by-page-paginator .item {
  color: #323232;
  padding: 1rem;
  margin: 0;
  border: none;
  border-right: 1px solid #e5e4e4;
  border-radius: 0;
  color: #323232;
  font-size: 0.89em;
  padding: 0.8rem;
  padding-bottom: 0.7rem;
  padding-top: 0.7rem;
  display: inline-flex;
  margin-right: -1px;
  font-size: 1rem;
  font-weight: 500;
}

.by-page-paginator .active {
  background: linear-gradient(90deg, #a0165b, #5e034d 100%);
  color: white;
}

.setup-order-column {
  text-transform: uppercase;
}
</style>