import { orderColumns, filterRanges } from "./OrderListData";
import _ from 'lodash'

import { orderStatusIds, tenderStatusIds } from "@/data/statuses";

export const OrderListFullBasicMixin = {
    data() {
        return {
            _filterSelects: [],
            _filterRanges: {},
        };
    },
    beforeCreate() {
        this.$store.commit("order/setColumnsInitial", orderColumns)
        this._filterSelects = {
            order_statuses: Object.values(orderStatusIds).map(item => {
                return { id: item.id, title: item.title }
            }),
            tender_statuses: Object.values(tenderStatusIds).map(item => {
                return { id: item.id, title: item.title }
            })
        };

        this._filterRanges = filterRanges;
    },
    computed: {
        ordersList() {
            return this.$store.getters["order/list"];
        },
        _orderColumns() {
            return this.$store.getters["order/columns"];
        },
        _orderColumnsShown() {
            return this._orderColumns.filter((item) => item.show !== false)
        },
        orderLoading() {
            return this.$store.getters["order/loading"];
        },
        appliedFilters() {
            return this.$store.getters["order/appliedFilters"];
        },
        isHideInactive() {
            return this.$store.getters["order/isHideInactive"];
        },
        showSetup() {
            return this.$store.getters["order/showSetup"];
        },
        currentPage() {
            return this.$store.getters["order/currentPage"];
        },
        perPage() {
            return this.$store.getters["order/perPage"];
        },
        totalCount() {
            return this.$store.getters["order/totalCount"];
        },
    },
    mounted() {
    },
    methods: {
        getFilterTitleByField(fieldName, value) {
            const column = this._orderColumns.find(item => item.field === fieldName);
            let _value = value;
            if (column.selectList && (column.selectList in this._filterSelects)) {
                _value = this._filterSelects[column.selectList].find(item => item.id === value).title;
            }

            let _label = column && column.label ? column.label : fieldName;
            _label = column && column.shortLabel ? column.shortLabel : _label;

            return `${_label}: ${_value}`
        },
        updateList() {
            this.$store.dispatch("order/load", { showMore: false, demoPage: false });
            this.syncFieldsStatesFromFilter();
        },
        changeFilterValue(column, value) {
            this.$store.commit("order/setFilterValue", { column, value });
            this.updateList()
        },
        getSelectedTitle(column, row) {
            const selectList = this._filterSelects[column.selectList];
            if (!selectList) return '';
            const field = column.field;

            const selectedItemId = row[field];
            const selectedItem = selectList.find(item => item.id === selectedItemId);

            return selectedItem ? selectedItem.title : '';
        },
        resetFilters(filterName = null) {
            if (filterName === null) {
                this.$store.commit("order/resetFilters")
                this.updateList()
            } else {
                this.changeFilterValue(filterName, null)
            }
        },
        syncFieldsStatesFromFilter() {
            for (let i = 0; i < this._orderColumns.length; i++) {
                let column = this._orderColumns[i];
                const field = column.field;
                let selected = column.selectedItem;

                if (field in this.appliedFilters) {
                    const filterValue = this.appliedFilters[field].value;
                    if (filterValue !== selected) {
                        selected = filterValue;
                    }
                } else {
                    this._orderColumns[i].selectedItem = null;
                    if (column.rangeable && (column.range[0] !== 1 || column.range[1] !== column.maxRange)) {
                        column.range[0] = 1;
                        column.range[1] = column.maxRange
                    }
                }
            }
        },
        forceUpdate() {
        },
        toggleShowSetup() {
            this.$store.commit("order/toggleShowSetup");
        },
        updateRange(column, range) {
            const rangeName = column.rangeName;
            let leftRange = range[0];
            let rightRange = range[1];

            if (leftRange === null) leftRange = this._filterRanges[rangeName][0];
            if (rightRange === null) rightRange = this._filterRanges[rangeName][1];

            leftRange = parseInt(leftRange);
            rightRange = parseInt(rightRange);

            this._filterRanges[rangeName][0] = leftRange;
            this._filterRanges[rangeName][1] = rightRange;
            this._filterRanges = { ...this._filterRanges }

            column.range = this._filterRanges[rangeName];

            this.changeFilterValue(column.field, column.range)
            //this.forceUpdate();
        },
    },
    watch: {
    }
};
