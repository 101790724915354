<template>
  <b-switch
    size="is-small"
    v-model="$store.state.order.isHideInactive"
    @input="updateList"
  ></b-switch>
</template>

<script>
import { OrderListFullBasicMixin } from "./OrderListFullBasicMixin";

export default {
  components: {},
  mixins: [OrderListFullBasicMixin],
};
</script>
