<template>
  <div class="shadow-tablet br-3 custom-border-tablet mb-5-5-tablet">
    <order-header />

    <div class="table-container">
      <div class="p-4 is-hidden-tablet custom-table">
        <!-- <OrderMobileCard
          v-for="order in ordersList"
          :key="order.id"
          :order="order"
        /> -->
      </div>

      <OrderListTable />
      <OrderListSetup />
    </div>
  </div>
</template>

<script>
import { OrderListFullBasicMixin } from "./OrderListFullBasicMixin";
import OrderMobileCard from "./OrderMobileCard";
import OrderHeader from "./OrderHeader";
import OrderListTable from "./OrderListTable";
import OrderListSetup from "./OrderListSetup";
import TimeIcon from "@/components/icons/TimeIcon.vue";
import CloseIcon from "@/components/icons/CloseIcon.vue";

export default {
  components: {
    OrderMobileCard,
    OrderHeader,
    OrderListTable,
    TimeIcon,
    CloseIcon,
    OrderListSetup,
  },
  mixins: [OrderListFullBasicMixin],
  mounted() {
    this.updateList();
  },
};
</script>

<style scoped>
.table-header-title {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #6f6f6f;
}
</style>
