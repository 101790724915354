<template>
  <section class="main-wrapper">
    <div class="main-content">
      <header-vue />
      <bread-crumbs :breadcrumbItems="breadcrumbItems" />

      <div class="container text-center-mobile-only pb-1 pl-5 pr-5 pt-5 mb-4">
        <div
          class="
            is-flex is-align-items-center is-justify-content-space-between
            mb-4
          "
        >
          <h2
            class="
              main-title
              is-size-3-5-mobile
              has-text-left has-text-centered-mobile
              mr-4
            "
          >
            Заявки
          </h2>
          <div class="has-text-right-mobile has-text-left-tablet">
            <span>
              <order-hide-inactive />
            </span>
            <span> Cкрыть неактивные </span>
          </div>
        </div>

        <order-list-full />

        <order-pagination />
      </div>
    </div>

    <footer-vue />
  </section>
</template>

<script>
import HeaderVue from "@/components/common/Header.vue";
import FooterVue from "@/components/common/Footer.vue";
import BreadCrumbs from "@/components/common/BreadCrumbs.vue";
import OrderListFull from "@/home/OrderListFull/OrderListFull.vue";
import OrderHideInactive from "@/home/OrderListFull/OrderHideInactive.vue";
import OrderPagination from "@/home/OrderListFull/OrderPagination.vue";

export default {
  components: {
    HeaderVue,
    FooterVue,
    BreadCrumbs,
    OrderListFull,
    OrderHideInactive,
    OrderPagination,
  },
  name: "Orders",
  data() {
    return {
      breadcrumbItems: [
        {
          title: "Заявки",
          link: null,
        },
      ],
    };
  },
};
</script>
